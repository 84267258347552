<template>
  <div class="consensu" :style="cssVars" id="consensu-box">
    <div class="consensu-box" :class="position">
      <div class="consensu-button animated bounce">
        <div class="consensu-button-check"></div>
      </div>
      <div class="consensu-container">
        <div class="consensu-overlay" id="consensu-overlay">
          <div class="consensu-sign-in" id="consensu-sign-in">
            <h1>{{ configs.message.title }}</h1>
            <p>{{ configs.message.description }}</p>
            <button
              class="consensu-switch-button"
              id="consensu-slide-right-button"
            >
              {{ configs.buttons.custom }}
            </button>
          </div>
          <div
            class="consensu-sign-up"
            id="consensu-sign-up"
            ref="consensuSignUp"
          >
            <h1>{{ configs.custom.title }}</h1>
            <p>{{ configs.custom.description }}</p>
            <button
              class="consensu-switch-button"
              id="consensu-slide-left-button"
            >
              {{ configs.buttons.back }}
            </button>
          </div>
        </div>

        <div class="consensu-form">
          <div class="consensu-sign-in" id="consensu-sign-in-info">
            <form id="consensu-sign-in-form" v-on:submit.prevent="onCustom">
              <p class="consensu-small">{{ configs.custom.info }}</p>
              <div class="consensu-cookie-list">
                <template v-for="item in cookies">
                  <div :key="item._id">
                    <button type="button" class="consensu-accordion">
                      {{ item.category[0].name }} ({{ item.cookies.length }})

                      <label class="switchCookie">
                        <input
                          type="checkbox"
                          class="category-button"
                          :id="`consensu-category-${item.category[0]._id}`"
                          :disabled="item.category[0].disabled"
                          :checked="item.category[0].disabled"
                        />
                        <span class="sliderCookie round"></span>
                      </label>
                    </button>
                    <div class="consensu-panel">
                      <span class="consensu-panel-desc"
                        ><b>Descrição</b> <br />{{
                          item.category[0].description
                        }}</span
                      >
                      <div
                        class="consensu-panel-item"
                        v-for="cItem in item.cookies"
                        :key="cItem.cookies._id"
                      >
                        <div class="consensu-panel-item-cookie">
                          <span class="consensu-panel-item-cookie-name">{{
                            cItem.cookies.name
                          }}</span>
                          <span
                            class="consensu-panel-item-cookie-description"
                            >{{ cItem.cookies.description }}</span
                          >
                          <span
                            class="consensu-panel-item-cookie-expiration"
                            v-if="cItem.cookies.expiration"
                            ><small
                              >Expiração: {{ cItem.cookies.expiration }}</small
                            ></span
                          >
                        </div>
                        <div class="consensu-panel-item-switch">
                          <label class="switchCookie consensu-switch-cookie">
                            <input
                              type="checkbox"
                              :class="
                                `consensu-category-${item.category[0]._id}-cookie`
                              "
                              :name="
                                `category[${item.category[0]._id}][cookies][]`
                              "
                              :value="cItem.cookies._id"
                              :disabled="item.category[0].disabled"
                              :checked="item.category[0].disabled"
                            />
                            <span class="sliderCookie round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>

              <button type="submit" class="consensu-control-button in">
                {{ configs.buttons.save }}
              </button>
            </form>
          </div>

          <div class="consensu-sign-up" id="consensu-sign-up-info">
            <h1>{{ configs.accept.title }}</h1>
            <p class="consensu-small">{{ configs.accept.description }}</p>
            <form id="consensu-sign-up-form">
              <button class="consensu-control-button up">
                {{ configs.buttons.accepted }}
              </button>
            </form>
            <div class="consensu-externals">
              <a
                v-if="configs.privacy.url"
                class="consensu-link"
                :href="configs.privacy.url"
                >Política de privadade</a
              >
              <a
                v-if="configs.terms.url"
                class="consensu-link"
                :href="configs.terms.url"
                >Termos de uso</a
              >
              <a
                v-if="configs.optOut.url"
                class="consensu-link"
                :href="configs.optOut.url"
                >Optout</a
              >
              <a
                v-if="configs.cookies.url"
                class="consensu-link"
                :href="configs.cookies.url"
                >Política de Cookies</a
              >
            </div>
            <div class="consensu-powered">
              Powered by
              <a href="https://consensu.io" target="_blank">Consensu.io</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
export default {
  name: "banner-box",
  props: ["configs", "cookies"],
  data() {
    return {
      positions: {
        "bottom-left": "consensu-position-bottom consensu-position-left",
        "bottom-right": "consensu-position-bottom consensu-position-right"
      }
    };
  },
  computed: {
    position() {
      return (
        this.positions[this.configs.position] || this.positions["bottom-left"]
      );
    },
    cssVars() {
      return {
        "---font": this.configs.style.font,
        "---font-size": this.configs.style.fontSize,
        "---font-size-desc": this.configs.style.fontSizeDesc,
        "---form-height": this.configs.style.formHeight,
        "---form-width": this.configs.style.formWidth,
        "---icon-background": this.configs.style.iconBackground,
        "---icon-color": this.configs.style.iconColor,
        "---icon-border": this.configs.style.iconBorder,
        "---icon-shadow": this.configs.style.iconShadow,
        "---primary-color": this.configs.style.primaryColor,
        "---secondary-color": this.configs.style.secondaryColor,
        "---list-color": this.configs.style.listColor,
        "---scroll-bar": this.configs.style.scrollBar
      };
    }
  },
  mounted() {
    if (this.configs && this.cookies) {
      this.init();
      this.accordion();
    }
  },
  watch: {
    cookies() {
      this.accordion();
    }
  },
  methods: {
    accordion() {
      var acc = document.getElementsByClassName("consensu-accordion");
      console.log("ACCORDION", acc);
      var i;

      for (i = 0; i < acc.length; i++) {
        acc[i].addEventListener("click", function() {
          /* Toggle between adding and removing the "active" class,
        to highlight the button that controls the panel */
          this.classList.toggle("active");

          /* Toggle between hiding and showing the active panel */
          var panel = this.nextElementSibling;
          if (panel.style.display === "block") {
            panel.style.display = "none";
          } else {
            panel.style.display = "block";
          }
        });
      }
    },
    serialize(form) {
      if (!form || form.nodeName !== "FORM") {
        return;
      }
      var i,
        j,
        q = [];
      for (i = form.elements.length - 1; i >= 0; i = i - 1) {
        if (form.elements[i].name === "") {
          continue;
        }
        switch (form.elements[i].nodeName) {
          case "INPUT":
            switch (form.elements[i].type) {
              case "text":
              case "hidden":
              case "password":
              case "button":
              case "reset":
              case "submit":
                q.push(
                  form.elements[i].name +
                    "=" +
                    encodeURIComponent(form.elements[i].value)
                );
                break;
              case "checkbox":
              case "radio":
                if (form.elements[i].checked) {
                  q.push(
                    form.elements[i].name +
                      "=" +
                      encodeURIComponent(form.elements[i].value)
                  );
                }
                break;
              case "file":
                break;
            }
            break;
          case "TEXTAREA":
            q.push(
              form.elements[i].name +
                "=" +
                encodeURIComponent(form.elements[i].value)
            );
            break;
          case "SELECT":
            switch (form.elements[i].type) {
              case "select-one":
                q.push(
                  form.elements[i].name +
                    "=" +
                    encodeURIComponent(form.elements[i].value)
                );
                break;
              case "select-multiple":
                for (
                  j = form.elements[i].options.length - 1;
                  j >= 0;
                  j = j - 1
                ) {
                  if (form.elements[i].options[j].selected) {
                    q.push(
                      form.elements[i].name +
                        "=" +
                        encodeURIComponent(form.elements[i].options[j].value)
                    );
                  }
                }
                break;
            }
            break;
          case "BUTTON":
            switch (form.elements[i].type) {
              case "reset":
              case "submit":
              case "button":
                q.push(
                  form.elements[i].name +
                    "=" +
                    encodeURIComponent(form.elements[i].value)
                );
                break;
            }
            break;
        }
      }
      return q.join("&");
    },

    async onCustom() {
      let consensu = document.getElementById("consensuBox");
      console.log("CONSENSU HTML", consensu);
      let form = document.getElementById("consensu-sign-in-form");
      let serialized = this.serialize(form);
      console.log("SAVEEEE", serialized);
      let token = await ApiService.generateToken({ serialized });
      ApiService.setHeader(token);
      ApiService.post(`consent/process`, serialized)
        .then(({ data }) => {
          console.log("DATA", data);
        })
        .catch(response => {
          console.log("DATA ERROR", response);
        });
    },
    init() {
      window._consensuIO = {};
      var leftText,
        rightText,
        accountForm,
        signInForm,
        overlay,
        openAcceptedButton,
        openCustomButton;
      window._consensuIO.triggers = () => {
        var btnPreferences = document.getElementsByClassName(
          "consensu-consent-preferences"
        );
        overlay = document.getElementById("consensu-overlay");
        // Buttons to 'switch' the page
        openAcceptedButton = document.getElementById(
          "consensu-slide-left-button"
        );
        openCustomButton = document.getElementById(
          "consensu-slide-right-button"
        );
        // The sidebars
        leftText = document.getElementById("consensu-sign-in");
        rightText = document.getElementById("consensu-sign-up");
        accountForm = document.getElementById("consensu-sign-in-info");
        signInForm = document.getElementById("consensu-sign-up-info");

        for (let i = 0; i < btnPreferences.length; i++) {
          btnPreferences[i].addEventListener("click", function() {
            this.classList.toggle("active");
            let form = this.parentElement.parentElement.parentElement
              .nextElementSibling;
            if (form.style.maxHeight) {
              form.style.maxHeight = null;
            } else {
              form.style.maxHeight = form.scrollHeight + "px";
            }
          });
        }
        // When a 'switch' button is pressed, switch page
        openAcceptedButton.addEventListener(
          "click",
          window._consensuIO.openAccepted,
          false
        );
        openCustomButton.addEventListener(
          "click",
          window._consensuIO.openCustom,
          false
        );
      };
      window._consensuIO.openAccepted = () => {
        //window._consensuIO.log("openAccepted");
        // Remove classes so that animations can restart on the next 'switch'
        leftText.classList.remove("consensu-overlay-text-left-animation-out");
        overlay.classList.remove("consensu-open-sign-in");
        rightText.classList.remove("consensu-overlay-text-right-animation");
        // Add classes for animations
        accountForm.className += " consensu-form-left-slide-out";
        rightText.className += " consensu-overlay-text-right-animation-out";
        overlay.className += " consensu-open-sign-up";
        leftText.className += " consensu-overlay-text-left-animation";
        // hide the sign up form once it is out of view
        setTimeout(function() {
          accountForm.classList.remove("consensu-form-left-slide-in");
          accountForm.style.display = "none";
          accountForm.classList.remove("consensu-form-left-slide-out");
        }, 700);
        // display the sign in form once the overlay begins moving right
        setTimeout(function() {
          signInForm.style.display = "flex";
          signInForm.classList += " consensu-form-right-slide-in";
        }, 200);
      };

      window._consensuIO.openCustom = () => {
        //window._consensuIO.log("openCustom");
        // Remove classes so that animations can restart on the next 'switch'
        leftText.classList.remove("consensu-overlay-text-left-animation");
        overlay.classList.remove("consensu-open-sign-up");
        rightText.classList.remove("consensu-overlay-text-right-animation-out");
        // Add classes for animations
        signInForm.classList += " consensu-form-right-slide-out";
        leftText.className += " consensu-overlay-text-left-animation-out";
        overlay.className += " consensu-open-sign-in";
        rightText.className += " consensu-overlay-text-right-animation";
        // hide the sign in form once it is out of view
        setTimeout(function() {
          signInForm.classList.remove("consensu-form-right-slide-in");
          signInForm.style.display = "none";
          signInForm.classList.remove("consensu-form-right-slide-out");
        }, 700);
        // display the sign up form once the overlay begins moving left
        setTimeout(function() {
          accountForm.style.display = "flex";
          accountForm.classList += " consensu-form-left-slide-in";
        }, 200);
      };
      window._consensuIO.triggers();
      document.querySelectorAll(".category-button").forEach(e => {
        e.addEventListener("change", event => {
          var checkboxes = document.querySelectorAll(`.${e.id}-cookie`);
          for (var checkbox of checkboxes) {
            checkbox.checked = event.currentTarget.checked;
          }
        });
      });
    }
  }
};
</script>
<style lang="css" scoped>
/* The switch - the box around the slider */
.switchCookie {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  float: right;
}

/* Hide default HTML checkbox */
.switchCookie input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.sliderCookie {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.sliderCookie:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switchCookie input:checked + .sliderCookie {
  background-color: var(---secondary-color);
}

.switchCookie input:focus + .sliderCookie {
  box-shadow: 0 0 1px var(---secondary-color);
}

.switchCookie input:checked + .sliderCookie:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

/* Rounded sliders */
.sliderCookie.round {
  border-radius: 34px;
}

.sliderCookie.round:before {
  border-radius: 50%;
}

/*
---------------------------------------
      VARIABLES
---------------------------------------
*/
/* ALLIN */

/* :root {
  ---font: "Helvetica Neue", sans-serif;
  ---form-height: 400px;
  ---form-width: 800px;
  ---icon-background: #f24518;
  ---icon-color: #fff;
  ---icon-border: #f83302;
  ---icon-shadow: #383838;
  ---icon-color: #ff7856;
  ---primary-color: #f24518;
  ---secondary-color: #383838;
  ---scroll-bar: #c3c3c3;
} */

/* VERDE */
/* :root {
  ---icon-background: #fff;
  ---icon-color: #9fdeaf;
  ---icon-border: #012b0b;
  ---icon-shadow: #012b0b;
  ---icon-color: #1b7a33;
  ---form-height: 400px;
  ---form-width: 800px;
  ---primary-color: #9fdeaf;
  ---secondary-color: #96dbe2;
  ---scroll-bar: #c3c3c3;
} */

/*
---------------------------------------
      BOX
---------------------------------------
*/
.consensu-box {
  position: sticky;
}

.consensu-position-top {
  top: 0;
}

.consensu-position-bottom {
  bottom: 0;
}

.consensu-position-left {
  left: 0;
}

.consensu-position-right {
  right: 0;
}

.consensu-button {
  background: var(---icon-background);
  height: 50px;
  width: 50px;
  margin: 10px;
  border-radius: 50%;
  border: 1px solid var(---icon-border);
  cursor: pointer;
}
.consensu-animation-bounce {
  animation: bounce 1s infinite alternate;
  -webkit-animation: bounce 1s infinite alternate;
}

.consensu-button:hover {
  animation: none;
}

.consensu-button-check {
  position: relative;
  top: 30px;
  left: 25px;
  width: 40px;
  height: 10px;
  background: var(---icon-color);
  transform-origin: 0 100%;
  transform: rotate(-45deg);
  box-shadow: 1px 1px 1px 1px var(---icon-shadow);
}

.consensu-button-check:before {
  content: "";
  position: absolute;
  left: -2px;
  top: -12px;
  bottom: 100%;
  width: 10px;
  height: 22px;
  background: var(---icon-color);
  box-shadow: inset -0.2rem -2rem 2rem var(---icon-color);
}

@keyframes bounce {
  from {
    transform: translateY(0px);
  }

  to {
    transform: translateY(-15px);
  }
}

@-webkit-keyframes bounce {
  from {
    transform: translateY(0px);
  }

  to {
    transform: translateY(-15px);
  }
}

body,
html {
  width: 100%;
  height: 100%;
  margin: 0;
  letter-spacing: 0.5px;
}

.consensu-container {
  width: var(---form-width);
  height: var(---form-height);
  font-family: var(---font);
  position: sticky;
  margin: auto;
  box-shadow: 2px 10px 40px rgba(22, 20, 19, 0.4);
  border-radius: 10px;
  margin-top: 50px;
  bottom: 20px;
  left: 70px;
  background: #fff;
}

.consensu-position-right .consensu-container {
  width: var(---form-width);
  height: var(---form-height);
  font-family: var(---font);
  position: sticky;
  margin: auto;
  box-shadow: 2px 10px 40px rgba(22, 20, 19, 0.4);
  border-radius: 10px;
  margin-top: 50px;
  bottom: 20px;
  right: 70px;
  background: #fff;
}

/*
---------------------------------
      Overlay
---------------------------------
*/

.consensu-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 100;
  background-image: linear-gradient(
    to right,
    var(---primary-color),
    var(---secondary-color)
  );
  border-radius: 10px;
  color: white;
  clip: rect(0, 385px, var(---form-height), 0);
}

.consensu-open-sign-up {
  animation: slideleft 1s linear forwards;
}

.consensu-open-sign-in {
  animation: slideright 1s linear forwards;
}

.consensu-overlay .consensu-sign-in,
.consensu-overlay .consensu-sign-up {
  /*  Width is 385px - padding  */
  ---padding: 50px;
  width: calc(450px - var(---padding) * 2);
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0px var(---padding);
  text-align: center;
}

.consensu-overlay .consensu-sign-in {
  float: left;
}

.consensu-overlay-text-left-animation {
  animation: text-slide-in-left 1s linear;
}

.consensu-overlay-text-left-animation-out {
  animation: text-slide-out-left 1s linear;
}

.consensu-overlay .consensu-sign-up {
  float: right;
}

.consensu-overlay-text-right-animation {
  animation: text-slide-in-right 1s linear;
}

.consensu-overlay-text-right-animation-out {
  animation: text-slide-out-right 1s linear;
}

.consensu-overlay h1,
.consensu-sign-up h1 {
  margin: 0px 5px;
  font-size: var(---font-size);
}

.consensu-overlay p,
.consensu-sign-up p {
  margin: 20px 0px 30px;
  font-weight: 200;
  font-size: var(---font-size-desc);
}

/*
------------------------------------
      Buttons
------------------------------------
*/

.consensu-switch-button,
.consensu-control-button {
  cursor: pointer;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: auto;
  padding: 10px;
  /* height: 40px; */
  font-size: 14px;
  text-transform: uppercase;
  background: none;
  border-radius: 20px;
  color: white;
}

.consensu-switch-button {
  border: 2px solid;
}

.consensu-control-button {
  border: none;
  margin-top: 15px;
}

.consensu-switch-button:focus,
.consensu-control-button:focus {
  outline: none;
}

.consensu-control-button.up {
  background-color: var(---primary-color);
}

.consensu-control-button.in {
  background-color: var(---secondary-color);
}

/*
---------------------------------------
      Forms
---------------------------------------
*/

.consensu-form {
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 10px;
}

.consensu-form .consensu-sign-up {
  ---padding: 50px;
  position: absolute;
  /*  Width is 100% - 385px - padding  */
  width: calc(var(---form-width) - 300px - var(---padding) * 2);
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0px var(---padding);
  text-align: center;
}

.consensu-form .consensu-sign-in {
  ---padding: 30px;
  position: absolute;
  /*  Width is 100% - 385px - padding  */
  width: calc(var(---form-width) - 325px - var(---padding) * 2);
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0px var(---padding);
  text-align: center;
}
#consensu-sign-in-form {
  height: 100%;
  width: 100%;
}

.consensu-form .consensu-sign-i
/* Sign in is initially not displayed */

.consensu-form .consensu-sign-in {
  display: none;
}

.consensu-form .consensu-sign-in {
  left: 0;
}

.consensu-form .consensu-sign-up {
  right: 0;
}

.consensu-form-right-slide-in {
  animation: form-slide-in-right 1s;
}

.consensu-form-right-slide-out {
  animation: form-slide-out-right 1s;
}

.consensu-form-left-slide-in {
  animation: form-slide-in-left 1s;
}

.form-left-slide-out {
  animation: form-slide-out-left 1s;
}

.consensu-form .consensu-sign-in h1 {
  color: var(---secondary-color);
  margin: 0;
}

.consensu-form .consensu-sign-up h1 {
  color: var(---primary-color);
  margin: 0;
}

.consensu-social-media-buttons {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 15px;
}

.consensu-social-media-buttons .icon {
  width: 40px;
  height: 40px;
  border: 1px solid #dadada;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 7px;
}

.consensu-small {
  font-size: 16px;
  color: grey;
  font-weight: 200;
  margin: 5px;
}

.consensu-social-media-buttons .icon svg {
  width: 25px;
  height: 25px;
}

#consensu-sign-in-form input,
#consensu-sign-up-form input {
  margin: 12px;
  font-size: 14px;
  padding: 15px;
  width: 260px;
  font-weight: 300;
  border: none;
  background-color: #e4e4e494;
  font-family: "Helvetica Neue", sans-serif;
  letter-spacing: 1.5px;
  padding-left: 20px;
}

#consensu-sign-in-form input::placeholder {
  letter-spacing: 1px;
}

.consensu-forgot-password {
  font-size: 12px;
  display: inline-block;
  border-bottom: 2px solid #efebeb;
  padding-bottom: 3px;
}

.consensu-forgot-password:hover {
  cursor: pointer;
}

/*
----------------------------------------
    Animation
----------------------------------------
*/

@keyframes slideright {
  0% {
    clip: rect(0, 385px, var(---form-height), 0);
  }

  30% {
    clip: rect(0, 480px, var(---form-height), 0);
  }

  /*  we want the width to be slightly larger here  */
  50% {
    clip: rect(
      0px,
      calc(var(---form-width) / 2 + 480px / 2),
      var(---form-height),
      calc(var(---form-width) / 2 - 480px / 2)
    );
  }

  80% {
    clip: rect(
      0px,
      var(---form-width),
      var(---form-height),
      calc(var(---form-width) - 480px)
    );
  }

  100% {
    clip: rect(
      0px,
      var(---form-width),
      var(---form-height),
      calc(var(---form-width) - 385px)
    );
  }
}

@keyframes slideleft {
  100% {
    clip: rect(0, 385px, var(---form-height), 0);
  }

  70% {
    clip: rect(0, 480px, var(---form-height), 0);
  }

  /*  we want the width to be slightly larger here  */
  50% {
    clip: rect(
      0px,
      calc(var(---form-width) / 2 + 480px / 2),
      var(---form-height),
      calc(var(---form-width) / 2 - 480px / 2)
    );
  }

  30% {
    clip: rect(
      0px,
      var(---form-width),
      var(---form-height),
      calc(var(---form-width) - 480px)
    );
  }

  0% {
    clip: rect(
      0px,
      var(---form-width),
      var(---form-height),
      calc(var(---form-width) - 385px)
    );
  }
}

@keyframes text-slide-in-left {
  0% {
    padding-left: 20px;
  }

  100% {
    padding-left: 50px;
  }
}

@keyframes text-slide-in-right {
  0% {
    padding-right: 20px;
  }

  100% {
    padding-right: 50px;
  }
}

@keyframes text-slide-out-left {
  0% {
    padding-left: 50px;
  }

  100% {
    padding-left: 20px;
  }
}

@keyframes text-slide-out-right {
  0% {
    padding-right: 50px;
  }

  100% {
    padding-right: 20px;
  }
}

@keyframes form-slide-in-right {
  0% {
    padding-right: 100px;
  }

  100% {
    padding-right: 50px;
  }
}

@keyframes form-slide-in-left {
  0% {
    padding-left: 100px;
  }

  100% {
    padding-left: 50px;
  }
}

@keyframes form-slide-out-right {
  0% {
    padding-right: 50px;
  }

  100% {
    padding-right: 80px;
  }
}

@keyframes form-slide-out-left {
  0% {
    padding-left: 50px;
  }

  100% {
    padding-left: 80px;
  }
}

.consensu-externals {
  display: flex;
  margin-bottom: 10px;
  font-size: 12px;
  width: 100%;
  position: absolute;
  bottom: 30px;
  align-content: center;
  justify-content: center;
}

.consensu-link {
  display: block;
  font-size: 12px;
  margin: 5px;
  color: var(---primary-color);
}

.consensu-powered {
  color: var(---secondary-color);
  margin-bottom: 10px;
  font-size: 12px;
  width: 100%;
  position: absolute;
  bottom: 0px;
  text-align: right;
}

.consensu-powered a {
  color: var(---primary-color);
  font-weight: bold;
  margin-right: 10px;
}

.consensu-cookie-list {
  width: calc(100% - 20px);
  height: calc(100% - 150px);
  overflow-y: auto;
  overflow-x: hidden;
  margin: 10px;
  /* padding: 10px; */
  border-radius: 10px;
  /* border: 2px solid  var(---primary-color); */
  /*
cursor: pointer;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 140px;
    height: 40px;
    font-size: 14px;
    text-transform: uppercase;
    background: none;
    border-radius: 20px; */
  /* color: white; */
}

/* width */

.consensu-cookie-list::-webkit-scrollbar {
  width: 10px;
}

/* Track */

.consensu-cookie-list::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(---secondary-color);
  border-radius: 10px;
}

/* Handle */

.consensu-cookie-list::-webkit-scrollbar-thumb {
  background: var(---secondary-color);
  border-radius: 10px;
}

/* Handle on hover */

.consensu-cookie-list::-webkit-scrollbar-thumb:hover {
  background: var(---secondary-color);
}

/* Style the buttons that are used to open and close the accordion panel */

.consensu-accordion {
  /* background-color: #eee; */
  /* color: var(---primary-color); */
  color: var(---secondary-color);
  font-weight: bold;
  cursor: pointer;
  padding: 5px 10px;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
  text-transform: capitalize;
}
button.consensu-accordion {
  /* border-bottom: 1px solid var(---scroll-bar); */
  border-bottom: 1px solid #f1f1f1;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */

.active,
.consensu-accordion:hover {
  /* background-color: var(---list-color); */
  background-color: #f1f1f1;
}

/* Style the accordion panel. Note: hidden by default */

.consensu-panel {
  padding: 0px 5px;
  background-color: white;
  display: none;
  overflow: hidden;
}
.consensu-panel-desc {
  text-align: left;
  font-size: 0.8em;
  width: 100%;
  display: block;
  border-bottom: 1px solid #ccc;
  padding: 5px 5px 5px 0px;
}

.consensu-panel-item {
  border-bottom: 1px solid #ccc;
  padding: 5px;
  width: 100%;
  text-align: left;
  position: relative;
}
.consensu-panel-item span {
}
.consensu-panel-item label {
  /* float:right; */
}

.consensu-panel-item-cookie {
  width: 85%;
  position: relative;
}

span.consensu-panel-item-cookie-name {
  font-weight: bold;
  float: left;
  clear: both;
  font-size: 14px;
}

span.consensu-panel-item-cookie-description {
  opacity: 0.8;
  clear: both;
  display: block;
  font-size: 13px;
}
span.consensu-panel-item-cookie-expiration {
  font-size: 12px;
}

.consensu-panel-item-switch {
  position: absolute;
  right: 15px;
  top: 5px;
}
</style>
